import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import * as ISOCountries from 'i18n-iso-countries';
import ReactCountryFlag from 'react-country-flag';
import { Row, Col, Typography, Button, Modal, Icon, Input } from 'antd';
import Container from '../../Container';
import { getGeocodeActionCreator, setCountryCodeActionCreator } from '../../../redux/geo/geo.actions';
import { RootModel } from '../../../redux/store';

import css from './country.module.scss';
import { usePrevious } from '../../../lib/hooks/usePrevious';

// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
// eslint-disable-next-line @typescript-eslint/no-var-requires
ISOCountries.registerLocale(require('i18n-iso-countries/langs/ru.json'));

export const Country: React.FC = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isXS = useMediaQuery({ maxWidth: 480 });
  const isMD = useMediaQuery({ minWidth: 480, maxWidth: 768 });

  const { Search } = Input;

  const [searchTerm, updateSearchTerm] = useState('');
  const [showModal, updateShowModal] = useState<boolean>(false);

  const [lat, updateLat] = useState();
  const [lng, updateLong] = useState();
  const prevLat = usePrevious(lat);
  const prevLng = usePrevious(lng);

  const [noNavigator, updateNoNavigator] = useState(false);

  const countries = Object.values(ISOCountries.getNames(i18n.language));

  useEffect(() => {
    if (typeof navigator !== 'undefined' && 'geolocation' in navigator) {
      updateNoNavigator(false);
      navigator.geolocation.getCurrentPosition((position: Position) => {
        updateLat(position.coords.latitude);
        updateLong(position.coords.longitude);
      });
    } else {
      updateNoNavigator(true);
    }
  }, []);

  const { countryCode, geoLoading } = useSelector((state: RootModel) => ({
    countryCode: state.geocode.countryCode,
    geoLoading: state.geocode.status.loading,
  }));

  const selectCountry = (c: string) => {
    dispatch(setCountryCodeActionCreator(ISOCountries.getAlpha2Code(c, i18n.language)));
    updateShowModal(false);
  };

  useEffect(() => {
    if (lat && lng && (lat !== prevLat || lng !== prevLng)) {
      dispatch(getGeocodeActionCreator({ location: { lat, lng } }));
      updateNoNavigator(false);
    }
    if (!lat && !lng) {
      updateNoNavigator(true);
    }
  }, [lat, lng]);

  useEffect(() => {
    if (countryCode !== undefined) {
      updateShowModal(false);
    }
  }, [countryCode]);

  return (
    <Container className={css.countryContainer}>
      <Button type="default" size="small" className={css.button} onClick={() => updateShowModal(true)}>
        {countryCode === undefined && noNavigator && (
          <>
            <Icon type="global" />
            {!(isXS || isMD) && <Typography.Text className={css.country}> {t('shell:country.choose')}</Typography.Text>}
          </>
        )}
        {countryCode !== undefined && (
          <>
            <ReactCountryFlag
              countryCode={countryCode}
              svg
              style={{
                width: '18px',
                height: '18px',
                marginRight: '4px',
              }}
            />
            {!(isXS || isMD) && (
              <Typography.Text className={css.country}> {ISOCountries.getName(countryCode, i18n.language)}</Typography.Text>
            )}
          </>
        )}
      </Button>
      <Modal visible={showModal} onCancel={() => updateShowModal(false)} className={css.countryModal}>
        <Row>
          <Col xs={24} md={12} style={{ paddingTop: '20px', paddingRight: isXS ? '0px' : '20px' }}>
            <Search
              onSearch={value => updateSearchTerm(value.toLowerCase())}
              onChange={e => updateSearchTerm(e.target.value.toLowerCase())}
            />
          </Col>
          <Col xs={24} md={12} style={{ paddingTop: '20px', paddingRight: isXS ? '0px' : '20px' }}>
            <Button
              block={isXS}
              disabled={geoLoading || noNavigator}
              onClick={e => {
                dispatch(getGeocodeActionCreator({ location: { lat, lng } }));
              }}
            >
              {geoLoading ? <Icon type="loading" /> : <Icon type="global" />}
              {noNavigator ? t('shell:country.allow') : t('shell:country.autodetect')}
            </Button>
          </Col>
        </Row>
        <Row type="flex" style={{ marginTop: '20px' }}>
          {countries.map(country => {
            if (country.toLowerCase().includes(searchTerm)) {
              return (
                <Col xs={24} sm={12} md={8} xl={4} key={country} className={css.countryItem}>
                  <Container className={css.flagContainer}>
                    <ReactCountryFlag
                      countryCode={ISOCountries.getAlpha2Code(country, i18n.language)}
                      svg
                      style={{
                        width: '30px',
                        height: '30px',
                        marginRight: '4px',
                        cursor: 'pointer',
                      }}
                      onClick={e => selectCountry(country)}
                    />
                    <div role="button" onClick={e => selectCountry(country)}>
                      <Typography.Text className={css.text}>{country}</Typography.Text>
                    </div>
                  </Container>
                </Col>
              );
            }
          })}
        </Row>
      </Modal>
    </Container>
  );
};
