import * as React from 'react';
// import { Link } from 'gatsby';
import Container from '../Container';
// import LangSelect from '../LangSelect';
import { HeaderMenu } from './Menu/Menu';
import { Logo } from './Logo/Logo';
import { HeaderCart as Cart } from './Cart/Cart';
import { LanguageSwitch } from './LanguageSwitch/LanguageSwitch';
import css from './header.module.scss';
import { Country } from './Country/Country';
import { HeaderManager as Manager } from './Manager/Manager';

const Header: React.FC = () => (
  <Container className={css.headerContainer}>
    <Container className={css.innerContainer}>
      <Container className={css.leftContainer}>
        <Logo />
        <HeaderMenu />
      </Container>
      <Container className={css.rightContainer}>
        <Country />
        <LanguageSwitch />
        <Manager />
        {/*<Cart />*/}
      </Container>
    </Container>
  </Container>
);

export default Header;
