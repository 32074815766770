import * as React from 'react';
import cx from 'classnames';
import css from './page.module.scss';

interface PageProps {
  className?: string;
}

const Page: React.FC<PageProps> = ({ children, className }) => <div className={cx(css.page, className)}>{children}</div>;

export default Page;
