import * as React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { Location } from '@reach/router';
import Header from '../components/Header/Header';
import LayoutRoot from '../components/LayoutRoot';
import LayoutMain from '../components/LayoutMain';
import { LangProvider, LangKey } from '../components/LangContext';
import css from './index.module.scss';
import Footer from '../components/Footer/Footer';
import '../i18n';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
      languages: {
        defaultLanguage: LangKey;
        languages: LangKey[];
      };
    };
  };
}

export interface LayoutProps {
  location?: { pathname: string };
  children: React.ReactNode;
}

const IndexLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        fragment PageFragment on ContentfulPage {
          slug
          # metaTitle
          # metaKeywords
          # metaDescription
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          node_locale
        }
        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
              keywords
              languages {
                defaultLanguage
                languages
              }
            }
          }
          # contentfulPage(slug: { eq: "catalog" }, node_locale: { eq: "ru-RU" }) {
          #  title
          # }
        }
      `}
      render={(data: StaticQueryProps) => (
        // eslint-disable-next-line no-restricted-globals
        <Location>
          {({ location }) => (
            <LangProvider
              pathname={location.pathname}
              langs={{
                languages: data.site.siteMetadata.languages.languages,
                defaultLangKey: data.site.siteMetadata.languages.defaultLanguage,
              }}
            >
              <LayoutRoot>
                <Helmet
                  title={data.site.siteMetadata.title}
                  meta={[
                    { name: 'description', content: data.site.siteMetadata.description },
                    { name: 'keywords', content: data.site.siteMetadata.keywords },
                    { name: 'google-site-verification', content: 'v6qIQtSy_fopEPN-qnskOJ69E4o_Yn9vDMFJTGfHcuk' },
                  ]}
                >
                  <script>{`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                      n.queue=[];t=b.createElement(e);t.async=!0;
                      t.src=v;s=b.getElementsByTagName(e)[0];
                      s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '542117065976902');
                    fbq('track', 'PageView');
                  `}</script>
                  <noscript>{`
                    <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=542117065976902&ev=PageView&noscript=1"/>
                  `}</noscript>
                </Helmet>
                <Header />
                <LayoutMain className={css.body}>{children}</LayoutMain>
                <Footer />
              </LayoutRoot>
            </LangProvider>
          )}
        </Location>
      )}
    />
  );
};

export default IndexLayout;
