import { navigate } from 'gatsby';
import React, { createContext, useEffect, useState } from 'react';
import { getCurrentLangKey, getLangs, getUrlForLang } from 'ptz-i18n';
import 'intl';

export type LangKey = 'en' | 'ru';

export interface Languages {
  languages: LangKey[];
  defaultLangKey: LangKey;
}

export const LangContext = createContext({});

export interface LangProviderProps {
  langs: Languages;
  pathname: string;
  children: React.ReactNode;
}

export interface LangConsumerProps {
  defaultLangKey?: string;
  langKey?: string;
  langsList?: {
    langKey: string;
    link: string;
    selected: boolean;
  }[];
  pathname?: string;
  toggleLanguage?: (value: string) => void;
}

export const LangProvider: React.SFC<LangProviderProps> = props => {
  const { langs, pathname, children } = props;

  const { languages, defaultLangKey } = langs;
  const currentLangKey = getCurrentLangKey(languages, defaultLangKey, pathname);
  const langsList = getLangs(languages, currentLangKey, getUrlForLang(`/${currentLangKey}/`, pathname));
  const [langKey, setLang] = useState(currentLangKey);

  useEffect(() => {
    const localLang = localStorage.getItem('lang');
    let lang = localLang || navigator.language.split('-')[0];
    const isSupportedLang = languages.find(l => lang === l);
    if (!isSupportedLang) lang = defaultLangKey;
    setLang(lang);

    if (langKey !== lang) {
      const prefix = lang === defaultLangKey ? '/' : `/${lang}/`;
      const splitKey = langKey === defaultLangKey ? '/' : `/${langKey}/`;
      const pathArray = pathname.split(splitKey);
      let to = '';
      if (pathArray[1]) [, to] = pathArray;
      else if (pathArray[0] !== '/') [to] = pathArray;
      navigate(`${prefix}${to}`);
    }
  });

  return (
    <LangContext.Provider
      value={{
        defaultLangKey,
        langKey,
        langsList,
        pathname,
        toggleLanguage: (value: string) => {
          setLang(value);
          localStorage.setItem('lang', value);
        },
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export const LangConsumer: React.SFC<{ children: (value: {}) => React.ReactNode }> = ({ children }) => (
  <LangContext.Consumer>{children}</LangContext.Consumer>
);
