import * as React from 'react';

interface ContainerProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Container: React.FC<ContainerProps> = props => {
  const { className, style, children } = props;
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default Container;
