/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { Menu, Icon, Button } from 'antd';
import { ClickParam, MenuProps } from 'antd/lib/menu';
import { useMediaQuery } from 'react-responsive';
import { Location } from '@reach/router';
import { menuItems } from '../../../constants/data';
import css from './menu.module.scss';
import Container from '../../Container';
import { pathTo } from '../../../lib/utils';

export const HeaderMenu: React.FC = () => {
  const { t } = useTranslation();
  const [currentKeys, updateCurrentKeys] = React.useState();
  const [collapsed, updateCollapsed] = React.useState(true);

  const handleMenuClick = (e: ClickParam) => {
    updateCurrentKeys(e.key);
  };

  const menuXSprops: MenuProps = {
    onClick: handleMenuClick,
    selectedKeys: [currentKeys],
    mode: 'inline',
    className: css.headerMenuSidebar,
    theme: 'dark',
    overflowedIndicator: (
      <Button type="primary" className={css.menuButton}>
        <Icon type="menu" />
      </Button>
    ),
  };

  const menuXLprops: MenuProps = {
    onClick: handleMenuClick,
    selectedKeys: [currentKeys],
    mode: 'horizontal',
    className: css.headerMenuHorizontal,
  };

  const isXS = useMediaQuery({ maxWidth: 480 });
  const isMD = useMediaQuery({ minWidth: 480, maxWidth: 768 });
  const isLG = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  const isXL = useMediaQuery({ minWidth: 1200 });

  const renderMenuItems = (): React.ReactNode => {
    return menuItems.map(item => {
      return (
        <Menu.Item key={pathTo(item.url)}>
          <Link to={pathTo(item.url)} className={css.link}>
            {t(`shell:mainMenu.${item.name}`)}
          </Link>
        </Menu.Item>
      );
    });
  };

  return (
    <Location>
      {location => {
        updateCurrentKeys(location.location.pathname);
        return (
          <>
            {(isXS || isMD) && (
              <Container className={css.burgerContainer}>
                <Button type="primary" size="large" className={css.menuButton} onClick={e => updateCollapsed(!collapsed)}>
                  <Icon type="menu" />
                </Button>
                {(isXS || isMD) && !collapsed && (
                  <Menu {...menuXSprops} defaultSelectedKeys={[location.location.pathname]}>
                    {renderMenuItems()}
                  </Menu>
                )}
              </Container>
            )}

            {(isLG || isXL) && (
              <Menu defaultSelectedKeys={[location.location.pathname]} {...menuXLprops}>
                {renderMenuItems()}
              </Menu>
            )}
          </>
        );
      }}
    </Location>
  );
};
