import * as React from 'react';
import Container from './Container';

interface LayoutMainProps {
  className?: string;
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => <Container className={className}>{children}</Container>;

export default LayoutMain;
