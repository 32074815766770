import * as React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { languages } from '../../../constants/locales';
import css from './languageswitch.module.scss';
import { LangConsumer, LangConsumerProps } from '../../LangContext';

export const LanguageSwitch = () => {
  const { i18n } = useTranslation();
  return (
    <LangConsumer>
      {({ langKey, langsList, toggleLanguage, defaultLangKey, pathname }: LangConsumerProps) => {
        if (!langKey || !langsList || !toggleLanguage || !defaultLangKey || !pathname) return null;
        const selected = langsList.filter(item => item.langKey === langKey)[0].langKey;
        const options = langsList.map(item => {
          return {
            label: item.langKey,
            value: item.langKey,
          };
        });
        const onChange = (lang: string) => {
          const selectedLang = langsList.find(item => item.langKey === lang) || langsList[0];
          const prefix = selectedLang.langKey === defaultLangKey ? '/' : selectedLang.link;
          const splitKey = selected === defaultLangKey ? '/' : `/${selected}/`;
          const pathArray = pathname.split(splitKey);
          let to = '';

          if (pathArray[1]) [, to] = pathArray;
          else if (pathArray[0] !== '/') [to] = pathArray;
          i18n.changeLanguage(lang);
          toggleLanguage(selectedLang.langKey);
          if (to === 'ru') {
            to = ''
          }
          navigate(`${prefix}${to}`);
        };

        return (
          <Radio.Group size="small" className={css.radiogroup} value={selected} onChange={e => onChange(e.target.value)}>
            {options.map((item, index) => {
              return (
                <Radio.Button key={item.value} value={item.value} className={css.switch} checked={item.value === selected}>
                  {item.label}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        );
      }}
    </LangConsumer>
  );
};
