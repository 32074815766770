import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { defaultLanguage } from '../../../constants/locales';
import * as logo from '../../../assets/logo.svg';
import Container from '../../Container';
import css from './logo.module.scss';

export const Logo: React.FC = () => {
  const { i18n } = useTranslation();
  const langPathPrefix: string = i18n.language === defaultLanguage ? '' : i18n.language;
  const homepage = `/${langPathPrefix}`;
  return (
    <Container style={{ alignSelf: 'flex-center', width: 'max-content', textAlign: 'center' }}>
      <Link to={homepage}>
        <img src={logo} alt="Go Slim" className={css.logoImage} style={{ cursor: 'pointer' }} />
      </Link>
    </Container>
  );
};
