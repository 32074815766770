import * as React from 'react';
import Container from './Container';

interface LayoutRootProps {
  className?: string;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => (
  <>
    <Container className={className}>{children}</Container>
  </>
);

export default LayoutRoot;
