import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Button, Tooltip } from 'antd';
import { Location, WindowLocation } from '@reach/router';
import { RootModel } from '../../../redux/store';
import css from './manager.module.scss';
import Container from '../../Container';
import {
  SetManagerActionCreator,
  ShowManagerActionCreator,
  ClearOrderActionCreator,
  ClearSavedOrderActionCreator,
} from '../../../redux/order/order.actions';
import { ClearCartActionCreator } from '../../../redux/cart/cart.actions';

export const HeaderManager = () => {
  const [location, updateLocation] = useState<WindowLocation | undefined>();
  const dispatch = useDispatch();

  const { manager, showManager } = useSelector((state: RootModel) => ({
    manager: state.order.manager,
    showManager: state.order.showManager,
  }));

  const handleParams = (l: WindowLocation) => {
    if (l.search) {
      const url = new URL(l.href);
      const managerParam = url.searchParams.get('manager');
      if (managerParam) {
        if (managerParam !== manager) {
          dispatch(ClearOrderActionCreator());
          dispatch(ClearSavedOrderActionCreator());
          dispatch(ClearCartActionCreator());
        }
        dispatch(SetManagerActionCreator(managerParam));
        dispatch(ShowManagerActionCreator(true));
      }
    }
  };

  React.useEffect(() => {
    if (location) {
      handleParams(location as WindowLocation);
    }
  }, [location]);

  return (
    <Location>
      {({ location: loc }) => {
        if (location !== loc) {
          updateLocation(loc);
        }
        return (
          <>
            {manager && showManager && (
              <Container className={css.buttonContainer}>
                <Tooltip placement="bottom" title={manager}>
                  <Button shape="circle" type="default" size="large" className={css.button}>
                    <Icon type="user" />
                  </Button>
                </Tooltip>
              </Container>
            )}
          </>
        );
      }}
    </Location>
  );
};
