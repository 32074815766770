import * as React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Container from '../Container';
import css from './footer.module.scss';
import { pathTo } from '../../lib/utils';

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container className={css.footerContainer}>
      <Container className={css.innerContainer}>
        <Container className={css.leftBlock}>
          Copyright 2020 © VEXOR TRADE OU <br />
          COMMERCIAL REGISTRY CODE 14179178 <br />
          VAT Number: EE101941424 <br />
          KATUSEPAPI TN 4, TALLINN 11412 ESTONIA <br />
        </Container>
        <Container className={css.rightBlock}>
          Website <br />
          <a href="https://goslim.pro">https://goslim.pro</a>
          <br />
          <Link to={pathTo('terms')}>{t('shell:terms.label')}</Link>
        </Container>
      </Container>
    </Container>
  );
};

export default Footer;
