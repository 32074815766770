import { MenuItem } from '../lib/common-interfaces';

export const menuItems: MenuItem[] = [
  {
    id: '1',
    name: 'home',
    url: '',
  },
  {
    id: '2',
    name: 'about',
    url: 'about',
  },
  {
    id: '3',
    name: 'faq',
    url: 'faq',
  },
  {
    id: '4',
    name: 'warranties',
    url: 'warranties',
  },
  {
    id: '5',
    name: 'shipping',
    url: 'shipping',
  },
];
